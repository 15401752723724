import classNames from 'classnames'
import React from 'react'
import Box from 'components/Box'
import Heading from 'components/Heading'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './Module1s.module.scss'
import Icon2 from '../Icon2'

type Props = {
  data: {
    copy: string
    icon: React.ReactNode
    title: string
  }[]
} & Component

export default function Module1s({ className, data }: Props) {
  const colors = ['purple', 'blue', 'cyan']

  return (
    <div className={classNames('Module1s', styles.this, className)}>
      {data.map((item, index) => (
        <Box align="center" color="white" key={item.title}>
          <Row size="xxxsmall">
            <Icon2 background={colors[index]}>
              <item.icon />
            </Icon2>
          </Row>
          <Heading level={3} levelStyle={4}>
            {item.title}
          </Heading>
          <Text element="p" flush>
            {item.copy}
          </Text>
        </Box>
      ))}
    </div>
  )
}
